import { tailwind } from "@theme-ui/presets"
require("typeface-kosugi-maru")
require("typeface-roboto")
require("typeface-roboto-mono")

const themeAmendments = {
  breakpoints: ["640px", "996px", "1280px"],
  colors: {
    text: "hsl(0, 0%, 19%)",
    background: "hsl(0, 0%, 100%)",
    primary: "hsl(25, 75%, 60%)",
    secondary: "hsl(165, 14%, 67%)",
    highlight: "hsl(47, 43%, 75%)",
    muted: "hsl(47, 44%, 78%)",
    gray: "hsl(48, 15%, 95%)",
    accent: "hsl(85, 24%, 71%)",
    darken: "hsl(22, 92%, 32%)",
    green: "hsl(68, 25%, 40%)",
    orange: "hsl(41, 57%, 48%)",
    mutedGreen: "hsl(71, 85%, 97%)",
  },
  sizes: {
    container: 1440,
  },
  fonts: {
    body: "Roboto, sans-serif",
    heading: '"Kosugi Maru", serif',
    monospace: '"Roboto Mono", monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  text: {
    display: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontSize: 6,
      letterSpacing: "0.1rem",
    },
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      overflowY: "scroll",
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    button: {
      fontFamily: "heading",
    },
  },
}
const crowApricot = { ...tailwind, ...themeAmendments }

export default crowApricot
