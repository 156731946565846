import React from "react";
import { ThemeProvider } from "theme-ui";
import { Global } from "@emotion/core";
import crowApricot from "./src/theme";
import * as H from "./src/components/headings"
import * as Text from "./src/components/text"

const components = {
  ...H,
  ...Text
};

export default ({ element }) => (
  <ThemeProvider theme={crowApricot} components={components}>
    <Global styles={{ body: { background: crowApricot.colors.background } }} />
    {element}
  </ThemeProvider>
);